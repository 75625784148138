import React, { useState, useEffect } from "react";
import './Home.css';
import jsonService from "../../services/jsonService";

function Home() {
    const getStatusClassName = (status) => {
        return status ? 'serverStatusOnline' : 'serverStatusOffline';
    };    

    const [serverData, setServerData] = useState({});
    const [serverInfo, setServerInfo] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const vitalsData = await jsonService.loadVitalsCheckupFiles();
                const infoData = await jsonService.loadServerInfoFiles();
                setServerData(vitalsData);
                setServerInfo(infoData);
                console.log("Loaded Server Info:", vitalsData, infoData);
            } catch (error) {
                console.error("Error loading server info:", error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 60000);
        
        return () => clearInterval(interval);
    }, []);

    const isServerOnline = (lastUpdateTime) => {
        // Get the current time in AEST
        const now = new Date();
        const nowAEST = new Intl.DateTimeFormat('en-AU', { timeZone: 'Australia/Melbourne', hour12: false }).format(now);
        console.log("Time now (AEST): " + nowAEST);
    
        // Parse the last update time as a local time (AEST)
        const lastUpdate = new Date(lastUpdateTime);
        console.log("Last update time (AEST): " + lastUpdate);
    
        // Calculate the difference in minutes
        const diffMinutes = (now - lastUpdate) / (1000 * 60);
        console.log("Difference in minutes: " + diffMinutes);
    
        // Return true if the difference is less than or equal to 40 minutes, otherwise false
        return diffMinutes <= 6;
    };

    const getLastUpdate = (serverData) => {
        console.log("Server data:", serverData);

        // Check if serverData is already an object
        if (typeof serverData === 'object') {
            const timestamps = Object.keys(serverData);
            console.log("Timestamps:", timestamps);
            const latestTimestamp = timestamps.sort().pop();
            console.log("Latest timestamp:", latestTimestamp);
            return latestTimestamp;
        }

        // Parse the JSON string into an object if it's a string
        let parsedData;
        try {
            parsedData = JSON.parse(serverData);
        } catch (error) {
            console.error("Error parsing server data:", error);
            return null;
        }
        
        const timestamps = Object.keys(parsedData);
        console.log("Timestamps:", timestamps);
        
        // Sort timestamps to ensure we get the latest one
        const latestTimestamp = timestamps.sort().pop();
        console.log("Latest timestamp:", latestTimestamp);
        
        return latestTimestamp;
    };

    const renderServerStatus = () => {
        return Object.entries(serverData).map(([hostname, data]) => {
            const lastUpdate = getLastUpdate(data);
            console.log("renderLastUpdate: " + lastUpdate)
            const isOnline = isServerOnline(lastUpdate);
            console.log("isOnline: " + isOnline)
            const serverInfoData = serverInfo[hostname];
            const label = serverInfoData ? serverInfoData.function : hostname;

            return (
                <div key={hostname} className="labelContainer">
                    <label>{label}:</label>
                    <label className={getStatusClassName(isOnline)}>
                        {isOnline ? 'Online' : 'Offline'}
                    </label>
                </div>
            );
        });
    };

    return (
        <div id="container">
            <h1>Welcome to the Home Page</h1>
            <div id="rightPanel">
                {renderServerStatus()}
            </div>
        </div>
    );
}

export default Home;
