import './Header.css';
import {  Link } from 'react-router-dom';
import shtshowLogo from '../../images/icons/shtshow.png';

function Header() {
  return (
    <div>
      <div className="header">
        <div className="logo-title">
          <img src={shtshowLogo} alt="Cockpit icon" width="150" height="70" />
          <Link to="/"><h2>Sht show home</h2></Link>
        </div>
        <nav className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/links">Links</Link>
        </nav>
      </div>
      <hr />
    </div>
  );
}

export default  Header;
