import axios from 'axios';

// Utility function to determine the base URL for JSON files
const getServerInfoBaseUrl = () => {
  if (window.location.hostname === 'localhost') {
    return '/data/server-info/';
  }
  return '/home/data/server-info/';
};

const getVitalsCheckupBaseUrl = () => {
  if (window.location.hostname === 'localhost') {
    return '/data/vitals-checkup/';
  }
  return '/home/data/vitals-checkup/';
};

// Function to load a single JSON file with cache-busting
const loadJsonFile = async (filePath) => {
  try {
    console.log(`Loading JSON file: ${filePath}`);
    const response = await axios.get(`${filePath}?timestamp=${new Date().getTime()}`);
    return response.data;
  } catch (error) {
    console.error(`Error loading JSON file: ${filePath}`, error);
    throw error;
  }
};

// Function to load all JSON files listed in the index file
const loadServerInfoFiles = async () => {
  const baseUrl = getServerInfoBaseUrl();
  const indexPath = `${baseUrl}index.json?timestamp=${new Date().getTime()}`;
  console.log(`Loading JSON files from: ${indexPath}`);
  // Fetch the index file to get the list of JSON files
  const indexResponse = await axios.get(indexPath);
  const fileNames = indexResponse.data.files;

  const jsonData = {};

  const filePromises = fileNames.map((fileName) => {
    const filePath = `${baseUrl}${fileName}`;
    return loadJsonFile(filePath).then((data) => {
      jsonData[fileName] = data;
    });
  });

  await Promise.all(filePromises);

  return jsonData;
};

const loadVitalsCheckupFiles = async () => {
  const baseUrl = getVitalsCheckupBaseUrl();
  const indexPath = `${baseUrl}index.json?timestamp=${new Date().getTime()}`;
  console.log(`Loading JSON files from: ${indexPath}`);
  // Fetch the index file to get the list of JSON files
  const indexResponse = await axios.get(indexPath);
  const fileNames = indexResponse.data.files;

  const jsonData = {};

  const filePromises = fileNames.map((fileName) => {
    const filePath = `${baseUrl}${fileName}`;
    return loadJsonFile(filePath).then((data) => {
      jsonData[fileName] = data;
    });
  });

  await Promise.all(filePromises);

  return jsonData;
};

// Exporting the functions to be used in other parts of the app
const jsonService = {
  loadServerInfoFiles,
  loadVitalsCheckupFiles,
  // Future methods for different types of files can be added here
};

export default jsonService;
