// src/components/ServerCard.js

import React from "react";
import "./ServerCard.css";

const ServerCard = ({ server, isRemote }) => {
  return (
    <div className="card">
      <h3>{server.function}:</h3>
      {server.links &&
        Object.keys(server.links).map((linkKey) => {
          const link = server.links[linkKey];
          return (
            <React.Fragment key={linkKey}>
              <a
                href={isRemote ? link.external : link.internal}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon ? (
                  <img
                    src={`/home/images/icons/${link.icon}.png`}
                    alt={link.altText}
                    width="70"
                    height="70"
                  />
                ) : (
                  <div className="link-name">{link.name}</div>
                )}
              </a>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default ServerCard;
