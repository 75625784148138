import React, { useState, useEffect } from "react";
import proxmoxLogo from "../../images/icons/proxmox.png";
import "./Links.css";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import jsonService from "../../services/jsonService";
import ServerCard from "../../components/card/serverCard/ServerCard";

function Links() {
  const [checked, setChecked] = useState(false);
  const [serverData, setServerData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = await jsonService.loadServerInfoFiles();
        setServerData(jsonData);
        console.log("Loaded Server Info:", jsonData);
      } catch (error) {
        console.error("Error loading server info:", error);
      }
    };

    fetchData();
  }, []);


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const renderCards = () => {
    return Object.keys(serverData).map((key) => {
      const server = serverData[key];
      return <ServerCard key={key} server={server} isRemote={checked} />;
    });
  };

  return (
    <div id="container">
      <h1>Server links</h1>

      <a
        href={checked ? "https://sht.show:8006" : "https://192.168.50.2:8006/"}
        target="_blank"
      >
        <img src={proxmoxLogo} alt="Proxmox icon" width="100" height="100" />
      </a>
      <div classname="envSwitch">
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              name="onOffSwitch"
              color="primary"
            />
          }
          label={checked ? "Remote" : "Local"}
        />
      </div>

      <hr />
      <div class="cardContainer">{renderCards()}</div>
    </div>
  );
}

export default Links;
