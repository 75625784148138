import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Header from './components/header/Header'

function App() {
  return (
    <Router basename="/home">
      <div className="App">
        <header className="App-header">
        </header>
        <Header />
        <main>
          <AppRoutes />
        </main>
      </div>
    </Router>
  );
}

export default App;
