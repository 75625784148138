// src/routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Links from './pages/links/Links';
import Home from './pages/home/Home';

function AppRoutes() {

  
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/links" element={<Links />} />
    </Routes>
  );
}

export default AppRoutes;
